.tab_main {
  display: flex;
  flex-direction: column;
}

.tab__tabTitle {
  display: flex;
}

.tab__tabTitle__leftPart {
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  padding-left: 16px;
  border-top-left-radius: 24px;
  width: 220px;
  height: 48px;

  color: #2e2e2e;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
}

.tab__tabTitle__rightPart {
  background-color: rgba(0, 0, 0, 0);
  border-top-right-radius: 10px;
  width: 60px;
  height: 48px;
}

.tab__content {
  border-radius: 16px;
  border-top-left-radius: 0px;
  background-color: white;
  padding: 16px;
  min-width: 900px;
}
