.tabParetnt {
  margin-top: 100px;
  margin-bottom: 100px;
}

.tabContent {
  padding: 10px;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  gap: 100px;
  color: black;
}

.tabContainer .ONYzq {
  color: black;
}

.MuiBox-root img {
  border: 4px solid black;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  background: black;
  margin-left: -10px;
  margin-right: 10px;
}

.MuiGrid-root > .MuiGrid-root.MuiGrid-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 12px;
  flex-basis: calc(33% - 8px);
  flex-grow: 0;
  flex-shrink: 0;
}

.MuiGrid-root.MuiGrid-container {
  gap: 16px;
  margin-top: 0;
  width: 100%;
}

.MuiContainer-root {
  padding: 0;
}

.MuiContainer-root div {
  margin: 0;
  min-width: fit-content;
}

.MuiCardContent-root {
  flex-wrap: wrap;
}

.MuiCardContent-root div:nth-child(1) div {
  background: #eee;
}

.MuiGrid-root.MuiGrid-container
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div {
  display: none;
}

/* Cash container */
.MuiContainer-root > div:first-of-type {
  /* Styl dla elementu z klasami 'sc-eqUAAy koMmWU' */
  gap: 15px;
  min-height: auto;
}

/* total vasted value */
.MuiContainer-root .MuiGrid-container .MuiGrid-item div {
  min-height: 0;
}

/* total vasted */
.MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(1) h5 {
  margin-bottom: 0;
  /* Styl dla 'Total Vested' */
}

/* total vasted value container*/
.MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(2) {
  margin: 0px 17px 0px 0px;
  display: flex;
  justify-content: flex-end;
}

/* total vasted value */
.MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(2) h1 {
  font-weight: 400;
}

/* total vasted IRL */
.MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(3) {
  margin: 0px 17px 0px 0px;
  display: flex;
  justify-content: flex-end;
  place-self: flex-end;
}

.MuiLinearProgress-root {
  display: none !important;
}

/* VEST box */
.MuiContainer-root .MuiCardContent-root div div:nth-of-type(2) {
  display: none;
}

/* Percent */
.MuiContainer-root .MuiCardContent-root h1 {
  display: none;
}

.MuiContainer-root .MuiGrid-container .MuiGrid-item .MuiBox-root h1 {
  /* Styl dla '0' przy 'Available to Claim' */
  font-weight: 400;
}

/* ---- vesting token schedule ---- */
/* 
div.MuiCardContent-root {
  padding-right: 0;
} */

/* ---- claim IRL button ----- */

.MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > div:nth-child(1) {
  display: none;
}

.MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > div:nth-child(2) {
  border-style: none;
  border-radius: 10px;
  margin-left: -12px;
}

.MuiGrid-root.MuiGrid-container
  .MuiGrid-root.MuiGrid-item
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > div:nth-child(3) {
  display: none;
}

@media (min-width: 600px) {
  div.MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
    width: auto;
  }
}
